* {
  background-color: #3d405b;
  color: #f4f1de;
  font-family: "Nanum Myeongjo", serif;
}

* > a {
  color: #e07a5f;
}
.link-logo > * {
  color: #ffffff;
}

.link-text {
  margin: 0 20px;
  font-size: 25px;

  text-decoration: none;
}

.title-capela {
  font-family: "Dela Gothic One", cursive;
  color: #f2cc8f;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 38px;
  letter-spacing: +0.07em;
}
.coloured {
  color: #81b29a;
  display: inline;
}
.title-secondName {
  margin: 5px auto;
  margin-bottom: 40px;
  font-size: 25px;
}

.nav-links {
  list-style: none;
  padding: 0;

  display: flex;
  justify-content: space-evenly;
}

.nav-links > li {
  padding: 0px 0px;

  text-align: center;
}

.link-logo {
  width: 60px;
  height: auto;
}

.title-logo {
  width: 350px;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
}

.title {
  display: block;
  text-align: center;
}

.title-text {
}

.title-title {
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  justify-content: center; /* Horizontally center the links */
  align-items: center; /* Vertically center the links */
  width: 100%;
  font-size: 28px;
}

.container {
  min-height: 98.4vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.content-wrapper {
  flex-grow: 3;
}

.footer-wrapper {
  width: 100%;

  margin-top: 100px;

  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  max-width: 900px;
}

.links {
  text-decoration: none;
}

.footer {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.promo {
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  max-width: 900px;
}

.promo-links {
  text-decoration: none;
  list-style-type: none;
}

.promo > a {
  text-decoration: none;
}

.cowlogos {
  border-radius: 8px;
  max-width: 300px;
}

.promo-caption {
  font-size: 30px;
  font-weight: bold;

  text-decoration: none;
}
